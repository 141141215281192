import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import healthinsurance from "../assets/images/healthinsurance.svg";
import TaskSheet from "../assets/images/TaskSheet.svg";
import TopLeftArrow from "../assets/images/topleftcrossarrow.svg";
import TopRightArrow from "../assets/images/topcrossarrow.svg";
import BottomLeftArrow from "../assets/images/downleftcrossarrow.svg";
import BottomRightArrow from "../assets/images/downcrossarrow.svg";
import flag from "../assets/images/flag.svg";
import CustomerAgent from "../assets/images/benefits1.svg";
import card from "../assets/images/card.png";
import card2 from "../assets/images/card2.png";
import { MdLocalPhone } from "react-icons/md";
import blueCaller from "../assets/images/blueCaller.svg";
import background from "../assets/images/background.svg";
import "./ReasonsforChoose.css";
import { phoneNumber } from "../Common";

const ReasonsforChoose = () => {
  return (
    <div className="bg-white text-black relative font-lato">
      <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8 py-10 font-lato">
        <h1 className="font-semibold text-4xl text-center my-2 text-[20px] md:text-[40px] md:font-[500] md:leading-[52px]">
          More Reasons To Choose Us
        </h1>
        <p className="my-4 text-md text-center md:text-[20px] text-[12px] md:leading-[28px] font-[400] leading-[16.8px]">
          We put your needs at the heart of everything we do.
        </p>

        <div className="flex flex-col md:flex-row md:gap-24">
          {/* 1st column web view */}
          <div className="flex-col items-center md:items-end max-w-md hidden md:block">
            {/* First item */}
            <div className="my-10 text-center md:text-right max-w-sm flex flex-col justify-end items-end">
              <LazyLoadImage
                src={healthinsurance}
                alt="Health Insurance"
                className="h-28 w-28 p-3 rounded-md"
                effect="blur"
              />
              <h2 className="font-semibold md:text-[20px] mt-2 font-lato text-[18px]">
                Top Insurance Carriers
              </h2>
              <p className="mt-3 md:text-[16px]">
                Plans offered by trusted providers like Humana, United
                Healthcare®, and many more.
              </p>
            </div>

            {/* Second item */}
            <div className="my-10 text-center md:text-right max-w-sm flex flex-col justify-end items-end">
              <LazyLoadImage
                src={TaskSheet}
                alt="TaskSheet"
                className="h-28 w-28 p-3 rounded-md"
                effect="blur"
              />
              <h2 className="font-semibold text-xl md:text-[20px] mt-2 font-lato text-[18px]">
                Easy Enrollment Process
              </h2>
              <p className="mt-3 md:text-[16px]">
                A simple, three-step process to help you find and enroll in the
                right plan for your needs.
              </p>
            </div>
          </div>

          {/* 1st column Mobile view */}
          <div className="flex items-center justify-center md:items-center md:hidden gap-[24px]">
            {/* First item */}
            <div className="my-3 text-center md:text-right max-w-sm flex flex-col justify-center items-center">
              <LazyLoadImage
                src={healthinsurance}
                alt="Health Insurance"
                className="h-28 w-28 p-3 rounded-md"
                effect="blur"
              />
              <h2 className="font-semibold text-xl md:text-[20px] mt-2 font-lato text-[18px]">
                Top Insurance Carriers
              </h2>
              <p className="mt-3 text-[12px] md:text-[14px]">
                Plans offered by trusted providers like Humana, United
                Healthcare®, and many more.
              </p>
            </div>

            {/* Second item */}
            <div className="my-3 text-center md:text-right max-w-sm flex flex-col justify-center items-center">
              <LazyLoadImage
                src={TaskSheet}
                alt="TaskSheet"
                className="h-28 w-28 p-3 rounded-md"
                effect="blur"
              />
              <h2 className="font-semibold text-xl md:text-[20px] mt-2 font-lato text-[18px]">
                Easy Enrollment Process
              </h2>
              <p className="mt-3 text-[12px] md:text-[14px]">
                A simple, three-step process to help you find and enroll in the
                right plan for your needs.
              </p>
            </div>
          </div>

          {/* Card with arrow images */}
          <div className="relative flex justify-center items-center order-first md:order-none md:mb-[85px]">
            <div className="relative w-full md:w-auto relative-container z-50">
              {/* Background Image */}
              <div className="absolute inset-0 w-full h-full bg-cover bg-center z-0 hidden md:block payment-card-media">
                <LazyLoadImage
                  alt="Background"
                  src={background}
                  effect="blur"
                  style={{ top: "-2pc" }}
                  className="w-full h-full"
                />
              </div>

              {/* Card Image */}
              <LazyLoadImage
                src={card}
                alt="Card"
                className="relative z-10 w-full h-full"
                effect="blur"
              />
            </div>

            {/* Arrow images */}
            <div className="absolute top-[142px] left-[-62px] transform -translate-x-1/2 -translate-y-1/2 hidden md:block">
              <LazyLoadImage
                src={TopLeftArrow}
                alt="Top Left Arrow"
                className="h-10 w-10"
                effect="blur"
              />
            </div>
            <div className="absolute left-[-58px] bottom-[185px] transform -translate-x-1/2 translate-y-1/2 hidden md:block">
              <LazyLoadImage
                src={BottomLeftArrow}
                alt="Bottom Left Arrow"
                className="h-10 w-10"
                effect="blur"
              />
            </div>
            <div className="absolute top-[142px] right-[-57px] transform translate-x-1/2 -translate-y-1/2 hidden md:block">
              <LazyLoadImage
                src={TopRightArrow}
                alt="Top Right Arrow"
                className="h-10 w-10"
                effect="blur"
              />
            </div>
            <div className="absolute right-[-59px] bottom-[185px] transform translate-x-1/2 translate-y-1/2 hidden md:block">
              <LazyLoadImage
                src={BottomRightArrow}
                alt="Bottom Right Arrow"
                className="h-10 w-10"
                effect="blur"
              />
            </div>
          </div>

          {/* 5th column with web view*/}
          <div className="flex-col items-center md:items-end max-w-md hidden md:block">
            <div className="my-10 text-center md:text-start max-w-sm flex flex-col justify-start items-start">
              <LazyLoadImage
                src={CustomerAgent}
                alt="Health Insurance"
                className="h-28 w-28 p-3 rounded-md"
                effect="blur"
              />
              <h2 className="font-semibold text-xl md:text-[20px] mt-2 font-lato text-[18px]">
                Always Here to Help
              </h2>
              <p className="mt-3 md:text-[16px]">
                Our dedicated team is committed to providing ongoing support
                whenever you need assistance.
              </p>
            </div>
            <div className="my-10 text-center md:text-start max-w-sm flex flex-col justify-start items-start">
              <LazyLoadImage
                src={flag}
                alt="Health Insurance"
                className="h-28 w-28 p-3 rounded-md"
                effect="blur"
              />
              <h2 className="font-semibold text-xl md:text-[20px] mt-2 font-lato text-[18px]">
                Trusted Nationwide
              </h2>
              <p className="mt-3">
                With a proven track record of satisfaction, people across the
                country rely on our services.
              </p>
            </div>
          </div>

          {/* 5th column with mobile view*/}
          <div className="flex items-center justify-center md:items-center md:hidden gap-3">
            <div className="my-3 text-center md:text-right max-w-sm flex flex-col justify-center items-center">
              <LazyLoadImage
                src={CustomerAgent}
                alt="Health Insurance"
                className="h-28 w-28 p-3 rounded-md"
                effect="blur"
              />
              <h2 className="font-semibold text-xl md:text-[20px] mt-2 font-lato text-[18px]">
                Always Here to Help
              </h2>
              <p className="mt-3 text-[12px] md:text-[14px]">
                Our dedicated team is committed to providing ongoing support
                whenever you need assistance.
              </p>
            </div>
            <div className="my-3 text-center md:text-right max-w-sm flex flex-col justify-center items-center">
              <LazyLoadImage
                src={flag}
                alt="Health Insurance"
                className="h-28 w-28 p-3 rounded-md"
                effect="blur"
              />
              <h2 className="font-semibold text-xl md:text-[20px] mt-2 font-lato text-[18px]">
                Trusted Nationwide
              </h2>
              <p className="mt-3 text-[12px] md:text-[14px]">
                With a proven track record of satisfaction, people across the
                country rely on our services.
              </p>
            </div>
          </div>
        </div>

        <div className="flex flex-col justify-center items-center my-8">
          <div className="text-center max-w-md text-[12px] md:text-[16px]">
            <span className="font-[700]">With a simple phone call</span>, you
            can easily and quickly compare Medicare Advantage plans
          </div>
          <a href={`tel:${phoneNumber}`}>
            <button className="mt-6 bg-[#F68712] hover:bg-orange-500 text-white py-3 px-16 rounded-full flex gap-3 justify-center items-center font-bold uppercase lg:w-auto w-full">
              Call Us Now <MdLocalPhone size={22} />
            </button>
          </a>

          <div className="ml-4 mt-4 relative flex justify-center items-center">
            <div className="flex gap-2 items-center justify-center">
              <div className="relative">
                <LazyLoadImage
                  src={blueCaller}
                  alt="Vector"
                  className="w-8 h-8"
                  effect="blur"
                />
                <div className="absolute top-[-3px] left-6 bg-green-500 rounded-full w-2 h-2 z-[99]"></div>
              </div>

              <a
                href={`tel:${phoneNumber}`}
                className="md:text-[18px] text-[14px] font-[500]"
              >
                {phoneNumber}
              </a>

              <div className="text-start mt-1 md:text-[14px] text-[10px] font-[400]">
                Call a licensed insurance agent
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReasonsforChoose;
