import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./Component/Header";
import HeroSection from "./Component/HeroSection";
import GroceryBenefitsStep from "./Component/GroceryBenefitsStep";
import PalnsAndBenefits from "./Component/PalnsAndBenefits";
import HelpwithYourGrocery from "./Component/HelpwithYourGrocery";
import Help from "./Component/Help";
import Benefits from "./Component/Benefits";
import ReasonsforChoose from "./Component/ReasonsforChoose";
import SayingSlider from "./Component/SayingSlider";
import FAQs from "./Component/FAQs";
import DontDelayPlansAndBenefits from "./Component/DontDelayPlansAndBenefits";
import Footer from "./Component/Footer";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsAndConditions from "./pages/TermsAndCondition";
import "@fontsource/inter";

function App() {
  return (
    <Router>
      <Routes>
        {/* Route for homepage */}
        <Route
          path="/"
          element={
            <>
              <Header />
              <HeroSection />
              <GroceryBenefitsStep />
              <PalnsAndBenefits />
              <HelpwithYourGrocery />
              <Help />
              <Benefits />
              <ReasonsforChoose />
              <SayingSlider />
              <FAQs />
              <DontDelayPlansAndBenefits />
              <Footer />
            </>
          }
        />
        {/* Route for privacy policy */}
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        {/* Route for terms and conditions */}
        <Route path="/terms-conditions" element={<TermsAndConditions />} />
      </Routes>
    </Router>
  );
}

export default App;
