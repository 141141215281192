import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Customer from "../assets/images/customer4.png";
import RightIcon from "../assets/images/rightIcon.svg";
import blueCaller from "../assets/images/blueCaller.svg";
import Star2 from "../assets/images/Star2.svg";
import { MdLocalPhone } from "react-icons/md";
import MeganShelton from "../assets/images/meganshelton.png";
import { phoneNumber } from "../Common";

const DontDelayPlansAndBenefits = () => {
  const renderStarImages = (rating) => {
    let stars = [];
    for (let i = 0; i < 5; i++) {
      stars.push(
        <LazyLoadImage
          key={i}
          src={Star2}
          alt={`Star ${i + 1}`}
          className="w-4 h-4"
          effect="blur"
        />
      );
    }
    return stars;
  };

  return (
    <div className="bg-gradient-to-b from-[#d1ebf5] to-white font-lato">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 flex flex-col-reverse md:flex-row items-center justify-between">
        {/* Left side content */}
        <div className="flex-1 mb-6 md:mb-0 order-2 md:order-1 lg:pr-5">
          <div className="flex items-center mb-4">
            {renderStarImages(5)}
            <p className="text-black md:text-[16px] ml-2 text-[11px]">
              {/* Rated <span className="font-bold">4.9</span> based on{" "} */}
              <span className="font-bold">100+</span> happy customers
            </p>
          </div>
          <h2 className="text-black font-[500] text-[20px] leading-[26px] mb-4 md:text-[40px] font-lato md:leading-[52px]">
            Don’t Delay! Claim Your Grocery Benefits Allowance Now
          </h2>
          <p className="text-black max-w-xl text-[12px] mb-6 md:leading-[28px] leading-[16.8px] md:text-[20px] font-lato">
            Don't miss out on the opportunity to access valuable benefits like
            the $1,000 or possibly more yearly grocery allowance.
          </p>
          <p className="text-black text-md max-w-xl text-[12px] md:text-[16px] leading-[16.8px] md:leading-[28px]">
            Call us now, and we’ll help you choose a Medicare Advantage Plan
            that’s right for you.
          </p>

          {/* Conditional rendering based on screen size */}
          <div className="my-3">
            <div className="flex flex-col md:flex-row items-center justify-start md:gap-6 gap-4">
              <a href={`tel:${phoneNumber}`}>
                <button className="mt-6 bg-[#F68712] hover:bg-orange-500 text-white py-3 px-16 rounded-full flex w-full gap-3 justify-center items-center font-bold uppercase lg:w-auto ">
                  Call Us Now <MdLocalPhone size={22} />
                </button>
              </a>

              <div className="text-black font-semibold hidden md:hidden lg:block sm:hidden">
                <div className="mt-4 flex justify-start items-center gap-3">
                  <div className="relative">
                    <LazyLoadImage
                      src={blueCaller}
                      alt="Vector"
                      className="w-8 h-8"
                      effect="blur"
                    />
                    <div className="absolute top-[-3px] left-6 bg-green-500 rounded-full w-2 h-2 z-[99]"></div>
                  </div>
                  <a href={`tel:${phoneNumber}`} className="text-xl">
                    {phoneNumber}
                  </a>
                </div>
                <div className="text-start text-sm mt-1">
                  Call a licensed insurance agent
                </div>
              </div>

              <div className="text-black font-semibold lg:hidden block md:hidden">
                <div className="flex gap-2 items-center justify-center">
                  <div className="relative">
                    <LazyLoadImage
                      src={blueCaller}
                      alt="Vector"
                      className="w-8 h-8"
                      effect="blur"
                    />
                    <div className="absolute top-[-3px] left-6 bg-green-500 rounded-full w-2 h-2 z-[99]"></div>
                  </div>

                  <a href={`tel:${phoneNumber}`} className="text-[14px]">
                    {phoneNumber}
                  </a>

                  <div className="text-start mt-1 text-[10px]">
                    Call a licensed insurance agent
                  </div>
                </div>
              </div>
            </div>

            <div className="hidden lg:hidden md:flex justify-center items-center relative gap-2 text-black mt-2">
              <div className="relative">
                <LazyLoadImage
                  src={blueCaller}
                  alt="Vector"
                  className="w-8 h-8"
                  effect="blur"
                />
                <div className="absolute top-[-3px] left-6 bg-green-500 rounded-full w-2 h-2 z-[99]"></div>
              </div>
              <a href={`tel:${phoneNumber}`} className="text-sm">
                {phoneNumber}
              </a>
              <div className="text-start text-sm mt-1">
                Call a licensed insurance agent
              </div>
            </div>
          </div>

          <div className="bg-white rounded-md shadow-sm p-4 my-5 max-w-xl block md:hidden">
            <div className="flex items-start justify-start">
              <LazyLoadImage
                src={MeganShelton}
                alt="Logo"
                className="h-[56px] w-[56px] mr-4 rounded-full"
                effect="blur"
              />
              <div className="flex flex-col my-auto">
                <div className="mb-2 flex">{renderStarImages(5)}</div>
                <div className="flex items-center">
                  <span className="mr-2 font-bold lg:text-[14px] text-[12px]">
                    Megan Shelton
                  </span>
                  <img src={RightIcon} alt="Avatar Image" className="h-4 w-4" />
                  <span className="text-gray-600 ml-2 text-[10px] font-[500]">
                    Verified Customer
                  </span>
                </div>
              </div>
            </div>

            <p className="text-gray-700 mt-2 text-[12px] md:text-[14px] lg:text-[15px] text-center">
              “Excellent service and professionalism! The agent I worked with
              was knowledgeable and took the time to really make sure I
              understood my new Medicare plan. She was able to find me a great
              deal with benefits that I didn’t even know I qualified for! I look
              forward to working with her and this agency in the future.”
            </p>
          </div>

          <div className="bg-white rounded-md shadow-sm p-4 my-5 max-w-xl hidden md:block">
            <div className="flex items-start justify-center gap-1">
              <div className="m-auto">
                <LazyLoadImage
                  src={MeganShelton}
                  alt="Logo"
                  className="h-20 w-20 mr-4 rounded-full m-auto"
                  effect="blur"
                />
              </div>
              <div className="flex-1">
                <div className="flex justify-start">{renderStarImages(5)}</div>

                <p className="text-gray-700 mt-2 lg:text-[15px] md:text-[14px] md:leading-[22.4px]">
                  “Excellent service and professionalism! The agent I worked
                  with was knowledgeable and took the time to really make sure I
                  understood my new Medicare plan. She was able to find me a
                  great deal with benefits that I didn’t even know I qualified
                  for! I look forward to working with her and this agency in the
                  future.”
                </p>
                <div className="flex items-center mt-2">
                  <div className="flex-1 flex justify-start items-center">
                    <span className="mr-2 uppercase font-bold md:text-[14px] text-[12px]">
                      Megan Shelton
                    </span>
                    <img src={RightIcon} alt="Avtar Image" />
                    <span className="text-gray-600 ml-2">
                      Verified Customer
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Right side content */}
        <div className="flex-1 order-1 md:order-2 mb-6 md:mb-0 md:ml-4">
          <LazyLoadImage
            className="h-auto w-full sm:h-auto sm:max-w-full object-cover rounded-md md:mt-[-55px]"
            src={Customer}
            alt="Hero Image"
            effect="blur"
          />
        </div>
      </div>
      <div className="md:py-14 py-5 font-inter">
        <h2 className="font-[500] md:font-[600] md:text-[24px] text-[20px] md:leading-[28px] leading-[24px] text-center mx-[62px] mb-2">
          Medicare Advantage plans offered by
        </h2>
        <p className="text-[#828282] text-center leading-[24px] text-[16px] md:text-[18px] font-[500] font-inter">
          UnitedHealthcare®, Aetna, Cigna, Humana, Anthem Blue Cross, WellCare
        </p>
      </div>
    </div>
  );
};

export default DontDelayPlansAndBenefits;
