import React from "react";
import telephone from "../assets/images/telephone.png";
import task from "../assets/images/task.png";
import shoppingBag from "../assets/images/shopping-bag.png";
import { LazyLoadImage } from "react-lazy-load-image-component";

const GroceryBenefitsStep = () => {
  const steps = [
    {
      image: telephone,
      title: "Call Us Now",
      description:
        "Speak with a friendly licensed agent who will review your eligibility and answer questions.",
    },
    {
      image: task,
      title: "Compare Plans",
      description:
        "Our knowledgeable agents will help you compare plans to find the best fit for you.",
    },
    {
      image: shoppingBag,
      title: "Get Your Grocery Allowance",
      description:
        "Enroll in your chosen plan and start enjoying your $1,000 or possibly more yearly grocery allowance benefit.",
    },
  ];

  return (
    <div className="bg-[#6ED1F9] text-black font-lato">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="md:mx-0 mx-[20px]">
          <h1 className="leading-[28px] md:leading-[40px] text-center mb-4 md:font-[500] md:text-[30px] font-[400] text-[20px] font-lato">
            Get Your Grocery Allowance Benefit In 3 Simple Steps
          </h1>
          <p className="text-center text-[12px] md:text-[16px] md:leading-[28px] leading-[16.8px] font-lato">
            We go out of our way to make the whole process easy and hassle-free.
          </p>
        </div>
      </div>

      <div className="bg-white text-black">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-10">
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 md:gap-[82px]">
            {/* Map over steps array */}
            {steps.map((step, index) => (
              <div key={index} className="flex flex-col items-center">
                <LazyLoadImage
                  src={step.image}
                  alt={step.title}
                  className="h-36 my-3"
                  effect="blur"
                />
                <div className="bg-orange-400 rounded-full h-8 w-8 flex items-center justify-center mb-3">
                  <div className="text-white font-bold text-lg">
                    {index + 1}
                  </div>
                </div>
                <h2 className="text-[18px] md:text-[25px] font-bold mb-2 text-center my-3">
                  {step.title}
                </h2>
                <p className="text-[12px] md:text-[16px] text-center my-3">
                  {step.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GroceryBenefitsStep;
