import React from "react";
import HeroSecImage from "../assets/images/HeroSecImage.png";
import RightIcon from "../assets/images/rightIcon.svg";
import AlexSmithLogo from "../assets/images/AlexSmithLogo.png";
import Star2 from "../assets/images/Star2.svg";
import { MdLocalPhone } from "react-icons/md";
import CountUp from "react-countup";
import { phoneNumber } from "../Common";

const HeroSection = () => {
  const renderStarImages = (rating) => {
    let stars = [];
    for (let i = 0; i < 5; i++) {
      if (i < rating) {
        stars.push(
          <img key={i} src={Star2} alt={`Star ${i + 1}`} className="w-4 h-4" />
        );
      } else {
        stars.push(
          <img key={i} src={Star2} alt={`Star ${i + 1}`} className="w-4 h-4" />
        );
      }
    }
    return stars;
  };

  return (
    <div className="bg-gradient-to-b from-[#d1ebf5] to-white font-lato">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 md:py-12 py-3 flex flex-col-reverse md:flex-col xl:flex-row items-center justify-between">
        {/* Left side content */}
        <div className="flex-1 mb-6 md:mb-0 order-4 md:order-1 lg:min-w-[641px]">
          <div className="flex items-center mb-4">
            {renderStarImages(5)}
            <p className="text-black md:text-[16px] ml-2 text-[11px]">
              {/* Rated <span className="font-bold">4.9</span> based on{" "} */}
              <span className="font-bold">100+</span> happy customers
            </p>
          </div>
          <h2 className="font-bold text-[12px] lg:text-2xl mb-4 text-black font-lato">
            ATTENTION MEDICARE & MEDICAID RECIPIENTS!
          </h2>

          <p className="text-black text-[20px] lg:text-[40px] font-[500] mb-5 font-lato leading-[26px] lg:leading-[52px]">
            A Grocery Allowance Benefit May Be Available to You!
          </p>
          <p className="text-black font-[400] max-w-xl md:text-[20px] md:leading-[28px] leading-[16.8px] text-[12px] mb-5 font-lato">
            We’ll help you pick a plan that gives you a monthly allowance you
            can spend on groceries and other essentials.
          </p>
          <div className="my-5 lg:block hidden">
            <div className="flex gap-5 items-center my-2">
              <img src={RightIcon} alt="" />
              <div className="text-[18px]">
                {" "}
                $1,000 or possibly more yearly grocery allowance
              </div>
            </div>
            <div className="flex gap-5 items-center my-2">
              <img src={RightIcon} alt="" />
              <div className="text-[18px]">
                {" "}
                No extra costs with a $0 monthly premium
              </div>
            </div>
            <div className="flex gap-5 items-center my-2">
              <img src={RightIcon} alt="" />
              <div className="text-[18px]">
                {" "}
                Personalized support from licensed insurance agents
              </div>
            </div>
          </div>

          <a href={`tel:${phoneNumber}`}>
            <button className="mt-6 bg-[#F68712] hover:bg-orange-500 text-white py-3 px-16 rounded-full flex w-full gap-3 justify-center items-center font-bold uppercase lg:w-auto">
              Call Us Now <MdLocalPhone size={22} />
            </button>
          </a>

          <div className="bg-white rounded-md shadow-sm p-6 my-5 max-w-xl hidden md:block">
            <div className="flex items-start justify-center gap-1">
              <img
                src={AlexSmithLogo}
                alt="Logo"
                className="h-20 w-20 mr-4 rounded-full m-auto"
              />
              <div className="flex-1">
                <div className="flex justify-start">{renderStarImages(5)}</div>

                <p className="text-gray-700 mt-2 md:text-[16px] md:leading-[22.4px]">
                  “Their agent was very helpful in getting my mother in law
                  Medicare! There was a lot of question we had and they took
                  their time to answer them. Thank you!”
                </p>
                <div className="flex items-center mt-4">
                  <div className="flex-1 flex justify-start items-center">
                    <span className="mr-2 uppercase font-bold md:text-[14px] text-[12px]">
                      Alex Smith
                    </span>
                    <img src={RightIcon} alt="Avtar Image" />
                    <span className="text-gray-600 ml-2">
                      Verified Customer
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Mobile side View */}
          <div className="my-4 lg:hidden">
            <div className="flex gap-2 items-center my-2">
              <img src={RightIcon} alt="Right Icon" className="h-[18px]" />
              <div className="text-[12px]">
                {" "}
                $1,000 or possibly more yearly grocery allowance{" "}
              </div>
            </div>
            <div className="flex gap-2 items-center my-2">
              <img src={RightIcon} alt="RIght Icon" className="h-[18px]" />
              <div className="text-[12px]">
                {" "}
                No extra costs with a $0 monthly premium
              </div>
            </div>
            <div className="flex gap-2 items-center my-2">
              <img src={RightIcon} alt="Right Icon" className="h-[18px]" />
              <div className="text-[12px]">
                {" "}
                Personalized support from licensed insurance agents
              </div>
            </div>
          </div>
        </div>

        {/* Right side content */}
        <div className="flex-1 order-3 md:order-2 mb-6 md:mb-0 lg:relative lg:left-[-60px] lg:min-w-[541px] md:my-8">
          <img
            className="h-auto w-full sm:h-auto sm:max-w-full object-cover rounded-md"
            src={HeroSecImage}
            alt="Hero Image"
          />
        </div>

        {/* Card Section for Mobile */}
        <div className="bg-white rounded-md shadow-sm p-4 my-5 max-w-xl md:hidden">
          <div className="flex items-start justify-start">
            <img
              src={AlexSmithLogo}
              alt="Logo"
              className="w-[56px] h-[56px] mr-4 rounded-full"
            />
            <div className="flex flex-col justify-center">
              <div className="mb-2 flex">{renderStarImages(5)}</div>
              <div className="flex items-center">
                <span className="mr-2 font-bold lg:text-[14px] text-[12px]">
                  ALEX SMITH
                </span>
                <img src={RightIcon} alt="Avatar Image" className="h-4 w-4" />
                <span className="text-gray-600 ml-2 text-[10px] font-[500]">
                  Verified Customer
                </span>
              </div>
            </div>
          </div>

          <p className="text-gray-700 mt-2 text-[12px] md:text-[16px] font-[400]">
            “Their agent was very helpful in getting my mother in law Medicare!
            There was a lot of question we had and they took their time to
            answer them. Thank you!”
          </p>
        </div>

        {/* Reviews Section */}
        {/* <div className="flex md:flex-row lg:flex-col justify-center gap-5 items-center md:items-start order-1 md:order-2 my-6 lg:w-[155px] md:min-w-[180px] lg:min-w-[160px] md:py-5">
          <div className="md:pr-3 pr-3 lg:pr-0 border-r-2 lg:border-r-0 lg:ml-auto">
            <div className="text-end">
              <CountUp
                start={0}
                end={150}
                duration={2.5}
                separator=","
                suffix="+"
                className="font-[600] lg:text-[45px] md:text-[35px] text-[24px] my-3 md:text-end"
              />
            </div>
            <p className="mb-7 text-black font-semibold lg:text-end text-center text-[10px] lg:text-[14px] md:text-[12px] lg:whitespace-nowrap">
              Amazing Reviews
            </p>
            <hr className="hidden lg:block border border-solid border-[#5c5b5b33]" />
          </div>
          <div className="md:pr-3 pr-3 lg:pr-0 border-r-2 lg:border-r-0 lg:ml-auto">
            <div className="text-end">
              <CountUp
                start={0}
                end={10}
                duration={2.5}
                suffix="+"
                className="font-[600] lg:text-[45px] md:text-[35px] text-[24px] my-3 md:text-end"
              />
            </div>
            <p className="mb-7 text-black font-semibold md:text-end text-center text-[10px] lg:text-[14px] md:text-[12px] lg:whitespace-nowrap">
              Years of service
            </p>
            <hr className="hidden lg:block border border-solid border-[#5c5b5b33]" />
          </div>
          <div className="pr-3 md:pr-0 lg:ml-auto">
            <div className="text-end">
              <CountUp
                start={0}
                end={1000}
                duration={2.5}
                separator=","
                suffix="+"
                className="font-[600] lg:text-[45px] md:text-[35px] text-[24px] my-3 md:text-end"
              />
            </div>
            <p className="mb-7 text-black font-semibold md:text-end text-center text-[10px] lg:text-[14px] md:text-[12px] lg:whitespace-nowrap">
              Fresh Food Items
            </p>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default HeroSection;
