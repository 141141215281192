import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import HealthyFood from "../assets/images/healthyfood.svg";
import pills from "../assets/images/pills.svg";
import VectorIcon from "../assets/images/VectorIcon.svg";
import house from "../assets/images/house.svg";
import bill from "../assets/images/bill.svg";

const HelpwithYourGrocery = () => {
  // Define an array of objects for easier rendering
  const items = [
    { src: HealthyFood, alt: "Healthy Food", label: "Healthy Food" },
    {
      src: pills,
      alt: "Over-The-Counter Health Products",
      label: "Over-The-Counter Health Products",
    },
    { src: VectorIcon, alt: "Fresh Produce", label: "Fresh Produce" },
    {
      src: house,
      alt: "Rent/Mortgage Payments",
      label: "Rent/Mortgage Payments",
    },
    { src: bill, alt: "Utility Bills", label: "Utility Bills" },
  ];

  return (
    <div className="bg-[#6ED1F9] font-lato">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <h1 className="lg:font-[600] font-[500] lg:text-[40px] text-[24px] text-center  my-2 font-lato lg:leading-[52px] leading-[30px]">
          Help With Your Groceries, Rent/Mortgage, Bills, & More
        </h1>
        <p className="my-4 text-md text-center lg:text-[16px] text-[12px]">
          If you’re eligible, a Medicare Advantage Grocery Allowance can be used
          for:
        </p>

        {/* Grid Container */}
        <div className="flex flex-wrap justify-center gap-10 p-5">
          {/* Render each item */}
          {items.map((item, index) => (
            <div
              key={index}
              className="flex flex-col items-center justify-center h-full"
            >
              <LazyLoadImage
                src={item.src}
                alt={item.alt}
                className="h-28 w-28 bg-white p-3 rounded-md"
                effect="blur"
              />
              <div className="text-black font-semibold mt-2 text-center lg:max-w-[150px] max-w-[100px] md:text-[16px] text-[12px]">
                {item.label}
              </div>
            </div>
          ))}
        </div>
        {/* </div> */}
      </div>
    </div>
  );
};

export default HelpwithYourGrocery;
