import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Customer from "../assets/images/Customer.jpg";
import Customer2 from "../assets/images/Customer1.jpg";
import Customer3 from "../assets/images/Customer2.jpg";
import RightIcon from "../assets/images/rightIcon.svg";
import "./Help.css";

const Help = () => {
  return (
    <div className="bg-white text-black font-lato">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-10 my-7">
        <h1 className="text-center text-[20px] font-[500] leading-[24px] lg:font-[500] md:text-[40px] font-lato lg:leading-[52px] ">
          We’re Here To Help
        </h1>
        <p className="text-center text-[12px] font-[400] leading-[16.8px] lg:leading-[24px] my-5 mb-10 md:font-[400] md:text-[20px] md:leading-[28px]">
          We aim to help you get the most out of your Medicare & Medicaid, no
          strings attached.
        </p>

        <div className="flex flex-col lg:flex-row items-center lg:justify-between mb-10">
          <div className="lg:w-1/2 w-full image-wrapper">
            <LazyLoadImage
              src={Customer}
              alt="Customer Service"
              className="w-auto h-auto my-3 m-auto rounded-[25px]"
              effect="blur"
            />
          </div>
          <div className="lg:w-1/2 w-full text-lg my-3 lg:my-0 space-y-4 lg:space-y-6 max-w-[490px]">
            <h1 className="my-3 md:text-[30px] text-[18px] md:leading-[36px] font-[500]">
              $1,000 or possibly more Per Year for Healthy Eating
            </h1>
            <p className="text-[12px] md:text-[20px] font-[400] font-lato leading-[16.8px] md:leading-[28px]">
              Help with maintaining a balanced diet with a prepaid grocery
              Allowance.
            </p>
            <div className="lg:block my-4 md:space-y-5">
              <div className="flex gap-2 items-center my-2">
                <img src={RightIcon} alt="" className="h-[18px] md:h-auto" />
                <div className="md:text-[16px] text-[12px] leading-[14.4px]">
                  {" "}
                  Save on essential food items
                </div>
              </div>
              <div className="flex gap-2 items-center my-2">
                <img src={RightIcon} alt="" className="h-[18px] md:h-auto" />
                <div className="md:text-[16px] text-[12px] leading-[14.4px]">
                  {" "}
                  Purchase nutritious groceries easily
                </div>
              </div>
              <div className="flex gap-2 items-center my-2">
                <img src={RightIcon} alt="" className="h-[18px] md:h-auto" />
                <div className="md:text-[16px] text-[12px] leading-[14.4px]">
                  {" "}
                  Enjoy $1,000 or possibly more yearly allowance
                </div>
              </div>
            </div>
            <p className="md:text-[16px] text-[12px] leading-[16.8px] md:leading-[22.4px]">
              With a Grocery Allowance Benefit, you can focus on nourishing your
              body with wholesome foods without worrying about the impact on
              your wallet.
            </p>
          </div>
        </div>

        <div className="flex flex-col lg:flex-row items-center lg:justify-between mb-10">
          <div className="lg:w-1/2 w-full text-lg my-3 lg:my-0 space-y-5 max-w-[490px] order-2 md:order-none m-auto">
            <h1 className="my-3 md:text-[30px] text-[18px] md:leading-[36px] font-[500]">
              $0 Monthly Premium
            </h1>
            <p className="text-[12px] md:text-[20px] font-[400] font-lato leading-[16.8px] md:leading-[28px]">
              Access the benefits you need without worrying about additional
              expenses.
            </p>
            <p className="md:text-[16px] text-[12px] leading-[16.8px] md:leading-[22.4px]">
              Our commitment to providing affordable healthcare solutions allows
              you to focus on what matters most – your health and well-being.
            </p>
          </div>

          <div className="lg:w-1/2 w-full image-wrapper">
            <LazyLoadImage
              src={Customer2}
              alt="Customer Service"
              className="w-auto h-auto my-3 m-auto rounded-[25px]"
              effect="blur"
            />
          </div>
        </div>

        <div className="flex flex-col lg:flex-row items-center lg:justify-between">
          <div className="lg:w-1/2 w-full image-wrapper">
            <LazyLoadImage
              src={Customer3}
              alt="Customer Service"
              className="w-auto h-auto my-3 m-auto rounded-[25px]"
              effect="blur"
            />
          </div>
          <div className="lg:w-1/2 w-full text-lg my-3 lg:my-0 lg:ml-10 space-y-5 lg:space-y-10 max-w-[490px]">
            <h1 className="my-3 md:text-[30px] text-[18px] md:leading-[36px] font-[500]">
              Guiding You To The Right Plan
            </h1>
            <p className="text-[12px] md:text-[20px] font-[400] font-lato leading-[16.8px] md:leading-[28px]">
              Our friendly, licensed insurance agents provide you with
              assistance and support throughout.
            </p>
            <div className="lg:block my-4 md:space-y-5">
              <div className="flex gap-2 items-center my-2">
                <img src={RightIcon} alt="" className="h-[18px] md:h-auto" />
                <div className="md:text-[16px] text-[12px] leading-[14.4px]">
                  {" "}
                  Thorough review of your eligibility
                </div>
              </div>
              <div className="flex gap-2 items-center my-2">
                <img src={RightIcon} alt="" className="h-[18px] md:h-auto" />
                <div className="md:text-[16px] text-[12px] leading-[14.4px]">
                  {" "}
                  Expert guidance in finding the best plan
                </div>
              </div>
              <div className="flex gap-2 items-center my-2">
                <img src={RightIcon} alt="" className="h-[18px] md:h-auto" />
                <div className="md:text-[16px] text-[12px] leading-[14.4px]">
                  Simple and stress-free enrollment process
                </div>
              </div>
            </div>
            <p className="md:text-[16px] text-[12px] leading-[16.8px] md:leading-[22.4px]">
              We understand that navigating Medicare Advantage plans can be
              overwhelming. That’s why we're here to guide you every step of the
              way.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Help;
