import React, { useRef } from "react";
import Slider from "react-slick";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Star2 from "../assets/images/Star2.svg";
import { slides } from "../Utils/Slides";
import RightIcon from "../assets/images/rightIcon.svg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaArrowLeftLong, FaArrowRightLong } from "react-icons/fa6";
import "./saying-slider.css";

const SayingSlider = () => {
  const sliderRef = useRef(null);

  const renderStarImages = (rating) => {
    let stars = [];
    for (let i = 0; i < 5; i++) {
      stars.push(
        <LazyLoadImage
          key={i}
          src={Star2}
          alt={`Star ${i + 1}`}
          className={`w-4 h-4 ${i < rating ? "" : "opacity-50"}`}
        />
      );
    }
    return stars;
  };

  const handlePrev = () => {
    sliderRef.current.slickPrev();
  };

  const handleNext = () => {
    sliderRef.current.slickNext();
  };

  const PrevArrow = ({ onClick }) => (
    <button
      type="button"
      className="md:slick-prev absolute top-1/2 transform -translate-y-1/2 left-[-1px] md:left-2 z-10 prev-button md:ml-24 text-white sm:text-black lg:text-red-500"
      onClick={onClick}
    >
      <FaArrowLeftLong className="arrow-icon text-xl" />
    </button>
  );

  const NextArrow = ({ onClick }) => (
    <button
      type="button"
      className="md:slick-next absolute top-1/2 transform -translate-y-1/2 right-[4px] md:right-2 z-10 next-button md:mr-28 text-white sm:text-black lg:text-blue-500"
      onClick={onClick}
    >
      <FaArrowRightLong className="arrow-icon text-xl" />
    </button>
  );

  const sliderSettings = {
    customPaging: function (i) {
      return <button className="dot"></button>; // Wrap dot in a button
    },
    dotsClass: "slick-dots slick-thumb",
    dots: true,
    rows: 1,
    infinite: true,
    speed: 500,
    centerMode: true,
    centerPadding: "16%",
    slidesToShow: 3,
    slidesToScroll: 3,
    prevArrow: <PrevArrow onClick={handlePrev} />,
    nextArrow: <NextArrow onClick={handleNext} />,
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          centerPadding: "13%",
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerPadding: "80px",
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: "10px",
        },
      },
    ],
  };

  return (
    <div className="bg-[#6ED1F9] text-black overflow-hidden py-14 font-lato">
      <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
        <h1 className="font-bold md:text-[40px] md:leading-[52px] text-center my-5 font-lato text-[20px]">
          See What People Are Saying
        </h1>
        <p className="text-center font-semibold md:text-[20px] md:leading-[28px] text-[12px] leading-[16.8px]">
          Real reviews from our valued members.
        </p>
      </div>

      <div className="mt-8 mx-[28px] md:mx-[0px]">
        <Slider
          {...sliderSettings}
          className="mx-[-8px] nav-slider"
          ref={(slider) => (sliderRef.current = slider)}
        >
          {slides.map((slide, index) => (
            <div
              key={index}
              className="md:px-2 px-3 w-full sm:w-1/2 lg:w-1/3 mb-12 flex"
            >
              <div className="bg-white rounded-lg shadow-lg overflow-hidden mx-auto flex flex-col h-full">
                <LazyLoadImage
                  src={slide.image}
                  alt="Slider Image"
                  className="w-full h-52 sm:h-64 object-cover p-4"
                  effect="blur"
                />
                <div className="p-4 flex flex-col flex-1">
                  <div className="flex flex-col flex-grow">
                    <h2 className="text-[18px] text-center font-bold mb-2 md:text-[20px]">
                      {slide.title}
                    </h2>
                    <div className="flex items-center justify-center my-2 gap-1">
                      {renderStarImages(5)}
                    </div>
                    <p className="text-gray-700 mt-4 text-center md:text-[16px] text-[12px] overflow-auto">
                      {slide.review}
                    </p>
                  </div>
                  <div className="mt-4">
                    <div className="flex items-center justify-center">
                      <span className="mr-4 uppercase font-bold text-[12px] md:text-[14px]">
                        {slide.author}
                      </span>
                      <LazyLoadImage
                        src={RightIcon}
                        alt="Avatar Image"
                        className="w-4 h-4 rounded-full"
                        effect="blur"
                      />
                      <span className="text-black text-[10px] ml-1 md:text-[12px]">
                        {slide.verified ? "Verified customer" : ""}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default SayingSlider;
