import React from "react";
import logoIcon from "../assets/images/LogoIcon.svg";
import LogoText from "../assets/images/LogoText.svg";
import BUsiness from "../assets/images/BUsiness.svg";
import secureCombo from "../assets/images/secureCombo.svg";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";

const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div className="bg-[#6ED1F9] text-black font-lato">
      <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8 py-10 md:py-16">
        <div className="flex flex-col md:flex-row justify-between my-5">
          <div
            className="flex items-center mb-4 md:mb-0 cursor-pointer"
            onClick={scrollToTop}
          >
            <div className="mr-4">
              <LazyLoadImage src={logoIcon} alt="logoIcon" effect="blur" />
            </div>
            <div>
              <LazyLoadImage src={LogoText} alt="LogoText" effect="blur" />
            </div>
          </div>

          <div className="flex items-center justify-center">
            <div className="mr-4">
              <LazyLoadImage src={BUsiness} alt="BUsiness" effect="blur" />
            </div>
            <div>
              <LazyLoadImage
                src={secureCombo}
                alt="secureCombo"
                effect="blur"
              />
            </div>
          </div>
        </div>
        <div className="text-sm mt-4 md:text-base">
          <p className="my-5 md:text-[16px] md:leading-[20.8px] text-[10px] leading-[13px] font-[300]">
            Participating Sales Agencies represent plans that are insured or
            covered by a Medicare Advantage SNP, PDP, HMO, PPO, or PFFS
            organization with a Medicare Contract and/or a Medicare-approved
            Part D Sponsor. Enrollment in plans depends on contract renewal.
            Enrollment in a plan may be limited to certain times of the year
            unless you qualify for a Special Election Period, or you are in your
            Medicare Initial Election Period. Not all plans offer all of these
            benefits. Benefits may vary by carrier and location. Limitations and
            exclusions may apply.
          </p>
          <p className="md:text-[16px] md:leading-[20.8px] text-[10px] leading-[13px] font-[300]">
            The following disclaimer applies to our national offering: We do not
            offer every plan available in your area. Currently we represent 5
            organizations which offer 2082 products in your area. Please contact
            Medicare.Gov , 1-800-MEDICARE, or your local State Health Insurance
            Program (SHIP)to get information on all of your options. Benefits
            amounts are annualized amounts calculated over the entire plan
            period. Some plans may require that the benefit be used during a
            particular period of time, or it will be lost. The benefits
            mentioned are a part of a special supplemental program for the
            chronically ill or those with Medicare and Medicaid. Allowance
            amounts cannot be combined with other benefit allowances.
          </p>
          <p className="md:text-[16px] md:leading-[20.8px] text-[10px] leading-[13px] font-[300]">
            Benefit(s) mentioned may be part of a special supplemental program
            for chronically ill members with one of the following conditions:
            Diabetes mellitus, Cardiovascular disorders, Chronic and disabling
            mental health conditions, Chronic lung disorders, Chronic heart
            failure. This is not a complete list of qualifying conditions.
            Having a qualifying condition alone does not mean you will receive
            the benefit(s). Other requirements may apply. Allowance amounts
            cannot be combined with other benefit allowances.
          </p>
          <p className="border-b-[1px] border-[#0000001A] pb-4 md:pb-10 md:text-[16px] md:leading-[20.8px] text-[10px] leading-[13px]">
            To request plan information without providing personal information,
            please call to speak with a licensed insurance agent at the phone
            number. We are not affiliated with any plan or endorsed by any
            government entity or agency. This site is operated in partnership
            with Health is Wealth Marketing LLC, a licensed insurance agency. $0
            premium plans are not available in all areas. Enrollees must
            continue to pay their Medicare Part B Premium. Deductibles, copay,
            and coinsurance may apply. Products and services are provided
            exclusively by our partners, but not all offer the same plans or
            options. Descriptions are for informational purposes only and
            subject to change. We encourage you to shop around and explore all
            of your options. MULTIPLAN_HIWMLLCGBCOM2025_M
          </p>
        </div>

        <div className="flex md:flex-row justify-between my-5">
          <div className="flex flex-col mb-4 md:mb-0 md:text-[16px] md:leading-[20.8px] text-[10px] leading-[13px]">
            <div className="mb-1">Health is Wealth Marketing LLC</div>
            <div className="mb-1">98 E Montecito Ave</div>
            <div>Sierra Madre, CA 91016</div>
          </div>

          <div className="flex flex-col text-end md:text-[16px] md:leading-[20.8px] text-[10px] leading-[13px]">
            <div className="mb-1">
              <Link to="/privacy-policy" className="text-black hover:underline">
                Privacy Policy
              </Link>
            </div>
            <div>
              <Link
                to="/terms-conditions"
                className="text-black hover:underline"
              >
                Terms & Conditionss
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
