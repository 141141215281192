import React from "react";
import { useNavigate } from "react-router-dom";
import Vector from "../assets/images/Vector.svg";
import logo from "../assets/images/Logo.png";
import clock from "../assets/images/clock.svg";
import { phoneNumber } from "../Common";

const Header = () => {
  const navigate = useNavigate();

  const handleLogoClick = () => {
    navigate("/");
  };

  return (
    <div className="border border-stone-300 font-lato">
      {/* Header section */}
      <div className="bg-[#6ED1F9] md:p-4 flex justify-center items-center gap-3 relative h-[44px] font-Lato">
        <div className="text-black text-center font-[400] md:text-[16px] text-[12px]">
          Call a licensed insurance agent
        </div>
        <div className="top-0 right-0">
          <div className="top-0 right-0 relative">
            <img src={Vector} alt="Vector" className="w-7 h-7" />
            <div className="absolute top-[-3px] right-0 bg-green-500 rounded-full w-2 h-2"></div>
          </div>
        </div>
        <div className="text-black text-center font-[500] md:text-[20px] text-[14px]">
          <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
        </div>
      </div>

      {/* Secondary section */}
      <div className="bg-white text-black md:h-24 flex flex-col md:flex-row justify-between items-center max-w-7xl mx-auto px-4 my-8">
        <div className="flex items-center mb-2 md:mb-0">
          <img
            src={logo}
            alt="Logo"
            className="h-24 md:h-24 mr-4 cursor-pointer"
            onClick={handleLogoClick}
          />
        </div>
        <div className="flex items-center md:ml-auto mt-2 md:mt-0">
          <img
            src={clock}
            alt="Clock"
            className="md:h-auto md:w-12 md:mr-3 w-[20px] h-[20px]"
          />
          <div className="font-[400] md:text-[18px] text-[12px] ml-3">
            <p className="inline md:block">Monday - Friday 8AM - 6PM</p>
            <p className="inline md:block">Central Time TTY: 711</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
