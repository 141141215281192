import React, { useState } from "react";
import { FaPlus, FaMinus } from "react-icons/fa";

const faqs = [
  {
    question: "What is the Grocery Allowance Benefit and how can it help me?",
    answer:
      "The Grocery Allowance Benefit included on a Medicare Advantage Plan provides a monthly or quarterly allowance for purchasing healthy groceries. This benefit can help you save money on essential food items, making it easier to maintain a balanced diet without straining your budget.",
  },
  {
    question: "What does this cost?",
    answer:
      "There is no additional cost for enrolling and there is no obligation to enroll.",
  },
  {
    question: " How do I know if I qualify?",
    answer:
      "Eligibility depends on your specific circumstances, including, but not limited to, your income and any chronic health issues you may have, such as Diabetes, Heart Issues, or Lung Issues.",
  },
];

const AccordionItem = ({ faq, index, isOpen, onClick }) => (
  <div className="">
    <button
      className={`flex justify-between px-4 rounded-3xl items-center w-full py-4 text-left focus:outline-none border border-gray-200 ${
        isOpen ? "bg-[#DBF5FF] text-black" : "bg-white text-black"
      }`}
      onClick={() => onClick(index)}
    >
      <span className="font-medium mx-1 text-[12px] md:text-[20px] font-lato">
        {faq.question}
      </span>
      <span
        className={`text-lg p-2 rounded-full ${
          isOpen ? "bg-[#F68712]" : "border border-[#F68712]"
        }`}
      >
        {isOpen ? (
          <FaMinus color="white" size={12} />
        ) : (
          <FaPlus color="orange" size={12} />
        )}
      </span>
    </button>
    <div
      className={`overflow-hidden transition-all duration-500 ease-in-out transform ${
        isOpen ? "max-h-40 opacity-100" : "max-h-0 opacity-0"
      }`}
    >
      <div className="py-2 px-5 text-gray-700 text-[12px] md:text-[16px]">
        {faq.answer}
      </div>
    </div>
  </div>
);

const FAQs = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const handleAccordionClick = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="bg-white text-black font-lato">
      <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
        <h1 className="font-semibold capitalize text-center my-2 md:mb-6 text-[20px] leading-[52px] font-lato md:leading-[52px] md:text-[40px]">
          Frequently Asked Questions
        </h1>
        <p className="my-4 text-md text-center md:mb-12 md:text-[18px] md:leading-[28px] font-lato leading-[16.8px] text-[12px]">
          Do you need additional information? You can find it here.
        </p>
        <div className="space-y-4">
          {faqs.map((faq, index) => (
            <AccordionItem
              key={index}
              index={index}
              faq={faq}
              isOpen={openIndex === index}
              onClick={handleAccordionClick}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default FAQs;
