import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import BenefitsImg1 from "../assets/images/BenefitsImg.jpg";
import BenefitsImg2 from "../assets/images/BenefitsImg2.jpg";
import BenefitsImg3 from "../assets/images/BenefitsImg3.jpg";
import BenefitsImg4 from "../assets/images/BenefitsImg1.jpg";
import RightIcon from "../assets/images/rightIcon.svg";
import DebraWhite from "../assets/images/debrawhite.png";
import blueCaller from "../assets/images/blueCaller.svg";
import Star2 from "../assets/images/Star2.svg";
import { MdLocalPhone } from "react-icons/md";
import { phoneNumber } from "../Common";

const PlansAndBenefits = () => {
  const renderStarImages = (rating) => {
    let stars = [];
    for (let i = 0; i < rating; i++) {
      stars.push(
        <LazyLoadImage
          key={i}
          src={Star2}
          alt={`Star ${i + 1}`}
          className="w-4 h-4"
          effect="blur"
        />
      );
    }
    return stars;
  };

  return (
    <div className="bg-[#D9F4FF] text-black font-lato">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-10 flex flex-col-reverse md:flex-row items-center justify-between">
        {/* Left side content */}
        <div className="flex-1 mb-6 md:mb-0 order-2 md:order-1 lg:pr-10">
          <div className="flex items-center mb-4">
            {renderStarImages(5)}
            <p className="text-black md:text-[16px] ml-2 text-[11px]">
              {/* Rated <span className="font-bold">4.9</span> based on{" "} */}
              <span className="font-bold">100+</span> happy customers
            </p>
          </div>
          <h2 className="text-black mb-4 md:text-[40px] font-lato font-[400] lg:leading-[52px] lg:font-[500] text-[20px]">
            Discover Your Eligibility, Plans & Benefits
          </h2>
          <p className="text-black max-w-xl text-md mb-6 lg:text-[20px] text-[12px]">
            Understanding your Medicare Advantage plan options and eligibility
            may seem complex, but we're here to make it easy.
          </p>
          <p className="text-black text-sm max-w-xl lg:text-[16px]">
            <span className="font-bold">Call now</span> to connect with a
            licensed insurance agent and discover the best plan for you!
          </p>

          <div className="my-3">
            <div className="flex flex-col md:flex-row items-center justify-start md:gap-6 gap-4">
              <a href={`tel:${phoneNumber}`}>
                <button className="mt-6 bg-[#F68712] hover:bg-orange-500 text-white py-3 px-16 rounded-full flex w-full gap-3 justify-center items-center font-bold uppercase lg:w-auto ">
                  Call Us Now <MdLocalPhone size={22} />
                </button>
              </a>
              <div className="text-black font-semibold hidden md:hidden lg:block sm:hidden">
                <div className="mt-4 flex gap-2">
                  <div className="relative">
                    <LazyLoadImage
                      src={blueCaller}
                      alt="Vector"
                      className="w-8 h-8"
                      effect="blur"
                    />
                    <div className="absolute top-[-3px] left-6 bg-green-500 rounded-full w-2 h-2 z-[99]"></div>
                  </div>
                  <a
                    href={`tel:${phoneNumber}`}
                    className="text-[18px] lg:font-[600]"
                  >
                    {phoneNumber}
                  </a>
                </div>
                <div className="text-start text-sm mt-1 lg:text-[14px]">
                  Call a licensed insurance agent
                </div>
              </div>

              <div className="text-black font-semibold lg:hidden block md:hidden">
                <div className="flex gap-2 items-center justify-center">
                  <div className="text-start mt-1 text-[12px]">
                    Call a licensed insurance agent
                  </div>
                  <div className="relative">
                    <LazyLoadImage
                      src={blueCaller}
                      alt="Vector"
                      className="w-8 h-8"
                      effect="blur"
                    />
                    <div className="absolute top-[-3px] left-6 bg-green-500 rounded-full w-2 h-2 z-[99]"></div>
                  </div>

                  <a
                    href={`tel:${phoneNumber}`}
                    className="text-[14px] font-[600]"
                  >
                    {phoneNumber}
                  </a>
                </div>
              </div>
            </div>

            <div className="hidden lg:hidden md:flex justify-center items-center relative gap-2 text-black mt-2">
              <div className="relative">
                <LazyLoadImage
                  src={blueCaller}
                  alt="Vector"
                  className="w-8 h-8"
                  effect="blur"
                />
                <div className="absolute top-[-3px] left-6 bg-green-500 rounded-full w-2 h-2 z-[99]"></div>
              </div>
              <div className="absolute md:hidden top-[-3px] md:left-7 bg-green-500 rounded-full w-2 h-2"></div>
              <a href={`tel:${phoneNumber}`} className="text-sm font-[600]">
                {phoneNumber}
              </a>
              <div className="text-start text-sm mt-1">
                Call a licensed insurance agent
              </div>
            </div>
          </div>

          <div className="bg-white rounded-md shadow-sm p-4 my-5 max-w-xl md:block hidden">
            <div className="flex items-start justify-center gap-1">
              <div className="m-auto">
                <LazyLoadImage
                  src={DebraWhite}
                  alt="Logo"
                  className="h-20 w-20 mr-4 rounded-full m-auto"
                  effect="blur"
                />
              </div>
              <div className="flex-1">
                <div className="flex justify-start">{renderStarImages(5)}</div>

                <p className="text-gray-700 mt-2 md:text-[15px] md:leading-[22.4px]">
                  “What a great experience. I was so impressed I've been
                  recommending it to all my friends and family. Their enrollment
                  make it very easy to find the perfect plan and their customer
                  support is second to none. I wish I could give more than 5
                  stars.”
                </p>
                <div className="flex items-center mt-2">
                  <div className="flex-1 flex justify-start items-center">
                    <span className="mr-2 uppercase font-bold md:text-[14px] text-[12px]">
                      DEBRA WHITE
                    </span>
                    <img src={RightIcon} alt="Avtar Image" />
                    <span className="text-gray-600 ml-2">
                      Verified Customer
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Right side content */}
        <div className="flex-1 md:order-3 order-1 mb-6 md:mb-0 md:grid grid-cols-2 gap-4 pl-5 hidden">
          <div className="flex flex-col">
            <LazyLoadImage
              className="h-auto w-35 lg:ml-auto  md:ml-0 mb-4 sm:h-auto sm:max-w-full object-cover mt-4 rounded-[16px]"
              src={BenefitsImg1}
              alt="BenefitsImg2"
              effect="blur"
            />{" "}
            <LazyLoadImage
              className="h-auto w-full sm:h-auto sm:max-w-full object-cover mt-0 rounded-[16px]"
              src={BenefitsImg2}
              alt="BenefitsImg4"
              effect="blur"
            />
          </div>
          <div className="flex flex-col justify-center">
            <LazyLoadImage
              className="h-auto w-full sm:h-auto sm:max-w-full object-cover mb-7 rounded-[16px]"
              src={BenefitsImg3}
              alt="BenefitsImg1"
              effect="blur"
            />
            <LazyLoadImage
              className="h-auto w-full sm:h-auto sm:max-w-full object-cover rounded-[16px]"
              src={BenefitsImg4}
              alt="BenefitsImg3"
              effect="blur"
            />
          </div>
        </div>

        <div className="flex-1 md:order-3 order-1 mb-6 md:mb-0 grid grid-cols-2 gap-4 md:hidden">
          <div className="flex flex-col">
            <LazyLoadImage
              className="h-auto w-[125px] ml-auto mb-4 sm:h-auto sm:max-w-full object-cover rounded-[16px]"
              src={BenefitsImg1}
              alt="BenefitsImg2"
              effect="blur"
            />{" "}
            <LazyLoadImage
              className="max-h-[170px] w-auto sm:h-auto sm:max-w-full object-cover mt-0 rounded-[16px]"
              src={BenefitsImg2}
              alt="BenefitsImg4"
              effect="blur"
            />
          </div>
          <div className="flex flex-col justify-center">
            <LazyLoadImage
              className="max-h-[170px] w-auto sm:h-auto sm:max-w-full object-cover mb-5 rounded-[16px]"
              src={BenefitsImg3}
              alt="BenefitsImg1"
              effect="blur"
            />
            <LazyLoadImage
              className="h-auto w-[156px] object-cover rounded-[16px]"
              src={BenefitsImg4}
              alt="BenefitsImg3"
              effect="blur"
            />
          </div>
        </div>

        <div className="bg-white rounded-md shadow-sm p-4 my-5 max-w-xl md:hidden">
          <div className="flex items-start justify-start">
            <LazyLoadImage
              src={DebraWhite}
              alt="Logo"
              className="h-[56px] w-[56px] mr-4 rounded-full"
              effect="blur"
            />
            <div className="flex flex-col my-auto">
              <div className="mb-2 flex">{renderStarImages(5)}</div>
              <div className="flex items-center">
                <span className="mr-2 font-bold lg:text-[14px] text-[12px]">
                  DEBRA WHITE
                </span>
                <img src={RightIcon} alt="Avatar Image" className="h-4 w-4" />
                <span className="text-gray-600 ml-2 text-[10px] font-[500]">
                  Verified Customer
                </span>
              </div>
            </div>
          </div>

          <p className="text-gray-700 mt-2 text-[12px] md:text-[16px]">
            “What a great experience. I was so impressed I've been recommending
            it to all my friends and family. Their enrollment make it very easy
            to find the perfect plan and their customer support is second to
            none. I wish I could give more than 5 stars.”
          </p>
        </div>
      </div>
    </div>
  );
};

export default PlansAndBenefits;
