import Slider1 from "../assets/images/Slider1.png";
import Slider2 from "../assets/images/Slider2.png";
import Slider3 from "../assets/images/Slider3.png";

export const slides = [
  {
    image: Slider3,
    title: "100% Fresh FOOD",
    rating: 5,
    review:
      "Excellent service and professionalism! The agent I worked with was knowledgeable and took the time to really make sure I understood my new Medicare plan. She was able to find me a great deal with benefits that I didn’t even know I qualified for! I look forward to working with her and this agency in the future.",
    author: "Megan Shelton",
    verified: true,
  },
  {
    image: Slider2,
    title: "Amazing quality food",
    rating: 5,
    review:
      "Very professional! These people are great at what they do, and very thorough. They helped me save money, find extra benefits, and keep my doctors all with a smile! I am very thankful that I discovered this company for my needs! You have a client for life! Thank you!",
    author: "Steve Douglas",
    verified: true,
  },
  {
    image: Slider3,
    title: "100% Fresh FOOD",
    rating: 5,
    review:
      "Excellent service and professionalism! The agent I worked with was knowledgeable and took the time to really make sure I understood my new Medicare plan. She was able to find me a great deal with benefits that I didn’t even know I qualified for! I look forward to working with her and this agency in the future.",
    author: "Megan Shelton",
    verified: true,
  },
  {
    image: Slider3,
    title: "100% Fresh FOOD",
    rating: 5,
    review:
      "Excellent service and professionalism! The agent I worked with was knowledgeable and took the time to really make sure I understood my new Medicare plan. She was able to find me a great deal with benefits that I didn’t even know I qualified for! I look forward to working with her and this agency in the future.",
    author: "Megan Shelton",
    verified: true,
  },

  {
    image: Slider1,
    title: "Great Customer Service!",
    rating: 5,
    review:
      "The customer service is top of the line, the agents are very thorough and informative explaining everything and making buying insurance very clear, transparent, and seamless! I would give more than 5 stars if I could. 10/10 Definitely recommend!",
    author: "Edward Plaisance",
    verified: true,
  },
];
