import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Benefits1 from "../assets/images/benefits1.svg";
import Benefits2 from "../assets/images/benefits2.svg";
import Benefits3 from "../assets/images/benefits3.svg";

const benefitItems = [
  {
    image: Benefits3,
    alt: "Healthy Food",
    title: "100% All Fresh & Natural Items",
    description:
      "Enjoy a wide selection of wholesome, nutrient-rich groceries to support your healthy lifestyle.",
  },
  {
    image: Benefits2,
    alt: "5-Star Customer Rating",
    title: "5-Star Customer Rating",
    description:
      "Our customers consistently rate us highly for our exceptional service and personalized support.",
  },
  {
    image: Benefits1,
    alt: "Licensed Insurance Agent",
    title: "Licensed Insurance Agent",
    description:
      "Our knowledgeable, licensed agents are ready to guide you through the enrollment process.",
  },
];

const Benefits = () => {
  return (
    <div className="bg-[#6ED1F9] text-black py-10 font-lato">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex flex-col md:flex-row items-center justify-between">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
          {benefitItems.map((item, index) => (
            <div
              key={index}
              className="flex flex-col items-center text-center max-w-md mx-auto"
            >
              <LazyLoadImage
                src={item.image}
                alt={item.alt}
                className="h-28 w-28 p-3 rounded-md"
                effect="blur"
              />
              <h1 className="text-black font-semibold mt-2 text-xl my-2">
                {item.title}
              </h1>
              <p className="mt-3 text-[12px] md:text-[14px]">
                {item.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Benefits;
